



import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({
  name: 'home-card',
  props: {
    extraClasses: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
})
export default class HomeCard extends Vue {

  public extraClasses: { [key: string]: boolean };
  public title: string | TranslateResult;
  public description: string | TranslateResult;

}

