



import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import HomeCard from '@/_components/home-card/home-card.vue';
import {Location, Route} from 'vue-router';
import { mapGetters } from 'vuex';

@Component({
  name: 'home',
  components: {
    HomeCard
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
    }),
  }
})
export default class Home extends Vue {

  // DATA

  public cardSearchKeyword: string = '';

  // MAPPED GETTERS, STATE

  public readonly isAuthenticated: boolean;

  // METHODS

  public onCardSearchSubmit(): void { // TODO: refactoring - name this method accordingly
    const keywords = this.cardSearchKeyword.trim();
    if (keywords === '') {
      return;
    }
    this.$router.resolve({
      name: 'event-list',
      query: { kw: keywords }
    });
  }

  /* AW-1093 check if the user is authenticated
   */
  public async proceedAfterAuthCheck(targetRoute: Location): Promise<void> {
    if (this.isAuthenticated) {
      try {
        await this.$router.push(targetRoute);
      } catch {
        /* ignore */
      }
    } else {
      await this.$store.dispatch('authStore/setAuthPopupVisible', { isAuthPopupVisible: true, targetRoute: (targetRoute as Route) });
    }
  }

}
